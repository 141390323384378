import request from '../utils/request'

//获取分页
export const page = (query) => {
    return request({
        url: '/web/dict/page', ///api/web/dict/page
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

//修改状态
export const updateState = (id,state) => {
    return request({
        url: '/web/dict/updateState',
        method: 'get',
        params:{
            id,
            state
        }
    });
};

//添加
export const addDictionaries = (query) => {
    return request({
        url: '/web/dict/add', 
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
export const editDictionaries = (query) => {
    return request({
        url: '/web/dict/edit', 
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
