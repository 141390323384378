<template>
  <div style="padding: 20px">
  <div class="handle-box">
   <el-button type="primary" class="handle-del mr10" @click="adDict">新增</el-button>
   <el-input v-model="query.key" placeholder="字典" class="handle-input mr10"></el-input>
   <el-button type="primary" @click="getPage">搜索</el-button>
   <el-button type="info" @click="reset">重置</el-button>
 </div>
 <el-table
     :data="tableData"
     border
     class="table"
     ref="multipleTable" 
     header-cell-class-name="table-header"
     @selection-change="handleSelectionChange"
 >
   <!-- 展开列 -->
   <el-table-column type="expand" class="bdbottom" label="ID">
      <template #default="scope">
          {{scope.row.id}}
     </template> 
   </el-table-column>

   <el-table-column prop="dictDesc" label="标题文字"></el-table-column>
   <el-table-column prop="dictKey" label="键-Key"></el-table-column>
   <el-table-column prop="dictLabel" label="键描述-Label"></el-table-column>
   <el-table-column prop="dictValue" label="字典值-Value"></el-table-column>
   <el-table-column prop="dictSort" label="排序"  align="center"></el-table-column>
   <el-table-column prop="createTime" label="创建时间"></el-table-column>
   <el-table-column prop="state" label="状态">
    <template #default="scope">
            <el-tooltip :content="scope.row.state ==='开启'? '关闭后此字典键值将无法再使用':'确定要开启吗'" placement="top">
              <el-switch
                  @click="setState(scope.row.id,scope.row.state)"
                  v-model="scope.row.state"
                  active-value="开启"
                  inactive-value="禁用"
              >
              </el-switch>
            </el-tooltip>
          </template>


   </el-table-column>
   <el-table-column label="操作" width="180" align="center">
     <template #default="scope">
       <!--编辑按钮-->
       <el-button
                  @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
       <!--删除按钮-->
       <el-button @click="handleDelete(scope.$index, scope.row)"
                  >删除</el-button>
       <!--分配角色按钮-->
     </template>
   </el-table-column>
 </el-table>
 <div class="pagination">
   <el-pagination
       background
       layout="total, prev, pager, next"
       :current-page="query.pageIndex"
       :page-size="query.pageSize"
       :total="pageTotal"
       @current-change="handlePageChange"
   ></el-pagination>
 </div>
</div>
<el-dialog v-model="deptDialog" :title="title" width="1200px">
  <el-form v-loading="editLogin"  ref="addRules" :rules="addRules"  :model="editItem" style="padding: 20px 40px 20px 20px" label-width="90px">
        <el-row>
          <el-col :span="8">
            <el-form-item prop="dictDesc" label="标题文字">
              <el-input v-model="editItem.dictDesc"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="dictKey" label="字典键-key">
              <el-input v-model="editItem.dictKey"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="dictLabel" label="描述Label">
              <el-input v-model="editItem.dictLabel"></el-input>
            </el-form-item>
          </el-col> 
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item prop="dictValue" label="字典值">
              <el-input v-model="editItem.dictValue"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="state" label="状态">
              <el-select placeholder="选择类型" @change="changeStudentType" v-model="editItem.state">
                <el-option label="开启" value="开启"></el-option>
                <el-option label="关闭" value="关闭"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item prop="dictType" label="类型标识">
              <el-input v-model="editItem.dictType"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item prop="parent" label="上级字典">
              <el-input v-model="editItem.parent"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="dictSort" label="排序">
              <el-input v-model.number="editItem.dictSort"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="deptDialog = false">取消</el-button>
          <el-button type="primary" @click="add">确定</el-button>
        </span>
      </template>
    </el-dialog>
</template>
<script>
import {page,updateState,addDictionaries ,editDictionaries} from '../../api/dictionaries.js'
    export default{ 
        data(){ 
            return{ 
              title:'添加字典-键值',
              addItem:{
                dictValue:'',
                dictType:'',
                state:'',
                dictSort:null,
                dictLabel:'',
                dictKey:'',
                parent:'',
                dictDesc:'',
              },
              editItem:{
                dictValue:'',
                dictType:'',
                state:'',
                dictSort:null,
                dictLabel:'',
                dictKey:'',
                parent:'',
                dictDesc:'',
              },
              addRules:{
                dictSort:[
                { required: true, message: 'dictSort是必须的' },
                 { type: 'number', message: 'dictSort必须为数值' },
                ]
              },
              editLogin:false,
              deptDialog:false,
                tableData:[],
                pageTotal:'',
                query: {
                  key:'',
             roleName: "",
             regionId: "",
             campusId: "",
             pageIndex: 1,
             pageSize: 10
              },
            }
        },
        created(){ 
            this.getPage()
        },
        methods:{ 
          adDict(){
            this.title = '添加字典-键值'
            Object.keys(this.editItem).forEach((key) => (this.editItem[key] = ''));
            this.editItem.dictSort = null
            this.deptDialog = true
          },
          //获取分页
          getPage(){ 
            page(this.query).then(res=>{ 
                this.tableData = res.data.records
                this.pageTotal = res.data.total
            })
          },
          
          handlePageChange(val){ 
            this.query.pageIndex = val;
            this.getPage();
          },  
          add(){ 
            if(this.title == '添加字典-键值') {
              this.addItem = this.editItem
              addDictionaries(this.addItem).then(res=>{ 
                if(res.code == 200){ 
                  this.$message.success(`操作成功`);
                  this.getPage()
                }
              })
            }else {
              editDictionaries(this.editItem).then(res=>{ 
                if(res.code == 200){ 
                  this.$message.success(`操作成功`);
                  this.getPage()
                }
              })    
            }
            this.editItem = {}
            this.deptDialog = false  
          },
          setState(a,b){ 
            updateState(a,b).then(res=>{ 
              if (res.code == 200) {
              this.$message.success(`操作成功`);
               } else {
              this.$message.error(`操作失败`);
            }
            })
          },
          handleEdit(a,b){ 
            // console.log(a,b);
            this.editItem = JSON.parse(JSON.stringify(b)) 
            this.deptDialog = true
            this.title = "编辑字典-键值"
          }         
        }        
    }

</script>
<style scoped>
    .handle-box {
    margin-bottom: 20px;
    }
    
    .handle-select {
    width: 120px;
    }
    
    .handle-input {
    width: 300px;
    display: inline-block;
    }
    
    .table {
    width: 100%;
    font-size: 14px;
    }
    
    .red {
    color: #ff0000;
    }
    
    .mr10 {
    margin-right: 10px;
    }
    
    .table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
    }
    
    .bdbottom {
    border-bottom: 1px solid #eee;
    }
    
    .vcenter {
    display: flex;
    align-items: center;
    }
    </style>
    